import React from "react";
import "./Member.css";

const Member = ({ name, company, social }) => {
  return (
    <div className="member">
      <div className="member-details">
        <h3>{name}</h3>
        <p>{company}</p>
        <a href={social} target="_blank" rel="noopener noreferrer">
          Social Profile
        </a>
      </div>
    </div>
  );
};

export default Member;
