import React, { useState } from "react";
import "./Terminal.css";
import members from "./members.json";
import investments from "./investments.json";
import Investment from "./Investment";
import Member from "./Member";

const ITEMS_PER_VIEW = 6; // Number of items per view

const Terminal = () => {
  const [input, setInput] = useState("");
  const [history, setHistory] = useState([
    "> Based Angel Collective",
    "> It's Just A Group Chat",
    '> Type "ls portfolio" to see our portfolio companies',
    '> Type "ls people" to see our members',
    '> Type "info" to learn more about Gaj Capital',
  ]);
  const [showInvestments, setShowInvestments] = useState(false);
  const [showMembers, setShowMembers] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleInput = (event) => {
    if (event.key === "Enter") {
      let newHistory = [...history, `> ${input}`];
      if (input.toLowerCase() === "ls portfolio") {
        setShowInvestments(true);
        setShowMembers(false);
        setCurrentIndex(0);
      } else if (input.toLowerCase() === "ls people") {
        setShowMembers(true);
        setShowInvestments(false);
        setCurrentIndex(0);
      } else if (input.toLowerCase() === "info") {
        newHistory = [
          ...newHistory,
          "> Gaj Capital is an angel collective founded by Gajesh Naik, who leverages his extensive network of founders, developers, investors, and strategists. Originally initiated by Gajesh sharing investment opportunities with his close circle, the collective has evolved into a robust network comprising over 80 members—and growing. Gaj Capital focuses on investing in crypto-related companies, providing strategic advice and enhancing distribution channels. The collective, which includes many of Gajesh’s friends, pools resources to support innovative companies.",
        ];
        setShowInvestments(false);
        setShowMembers(false);
      } else {
        newHistory = [...newHistory, `> Unknown command: ${input}`];
        setShowInvestments(false);
        setShowMembers(false);
      }
      setHistory(newHistory);
      setInput("");
    }
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + ITEMS_PER_VIEW) % (showInvestments ? investments.length : members.length));
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - ITEMS_PER_VIEW + (showInvestments ? investments.length : members.length)) % (showInvestments ? investments.length : members.length));
  };

  return (
    <div className="terminal">
      <div className="header">Gaj Capital</div>
      <div className="content">
        {history.map((item, index) => (
          <p key={index} className="prompt">{item}</p>
        ))}
        {showInvestments && (
          <div className="investment-slider">
            <button onClick={prevSlide}>&lt;</button>
            <div className="investment-view">
              {investments.slice(currentIndex, currentIndex + ITEMS_PER_VIEW).map((company, index) => (
                <Investment
                  key={index}
                  name={company.name}
                  stage={company.stage}
                  logo={company.logo}
                  url={company.url}
                  description={company.description}
                />
              ))}
            </div>
            <button onClick={nextSlide}>&gt;</button>
          </div>
        )}
        {showMembers && (
          <div className="member-slider">
            <button onClick={prevSlide}>&lt;</button>
            <div className="member-view">
              {members.slice(currentIndex, currentIndex + ITEMS_PER_VIEW).map((member, index) => (
                <Member
                  key={index}
                  name={member.name}
                  company={member.company}
                  social={member.social}
                />
              ))}
            </div>
            <button onClick={nextSlide}>&gt;</button>
          </div>
        )}
      </div>
      <div className="input">
        <span className="prompt-symbol">{'>'}</span>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleInput}
          autoFocus
        />
      </div>
    </div>
  );
};

export default Terminal;
