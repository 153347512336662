import React from "react";
import "./Investment.css";

const Investment = ({ name, stage, logo, website, description }) => {
  return (
    <div className="investment">
      {/* <img src={logo} alt={`${name} logo`} className="investment-logo" /> */}
      <div className="investment-details">
        <h3>{name}</h3>
        <p>Stage: {stage}</p>
        <p>{description}</p>
        <a href={website} target="_blank">
          Visit Website
        </a>
      </div>
    </div>
  );
};

export default Investment;
